<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    :transition="$dialogTransition"
  >
    <VCard>
      <VCardTitle>
        <span class="headline">{{$t('supply.add_itemBtn')}}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol cols="12" lg="6" md="6" sm="12">
                <VTextField
                  outlined dense
                  :label="language.AMOUNT"
                  required
                  type="number"
                  min="1"
                  v-model="product.items_amount"
                  :error-messages="itemsAmountErrors"
                  color="success"
                ></VTextField>
              </VCol>
              <VCol cols="12" lg="6" md="6" sm="12">
                <VTextField
                  outlined dense
                  label="Bar Code"
                  required
                  type="number"
                  min="0"
                  v-model="product.ns_code"
                  :error-messages="nsCodeErrors"
                  color="success"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" sm="12">
                <VSelect
                  clearable
                  outlined
                  dense
                  small-chips
                  :label="language.WHERE"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  :items="cells"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  chips
                  v-model="product.uuid_cell"
                  :error-messages="uuidCellErrors"
                  color="success"
                  item-color="success"
                >
                </VSelect>
              </VCol>
              <VCol cols="12" md="6" sm="12">
                <VFileInput
                  dense
                  outlined
                  small-chips
                  v-model="product.image"
                  accept="image/png, image/jpeg"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                  placeholder="Фото"
                />
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="12" md="6" sm="12">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              dark
              @click="visibility = false"
              block
            >
              {{ language.CANCEL }}
            </VBtn>
          </VCol>
          <VCol cols="12" md="6" sm="12">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              dark
              block
              @click="onCreate"
            >
              {{ language.CREATE }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import language from '../../mixins/language';
import nsCodeMask from '../../validators/nsCodeMask';
import notifications from '../../mixins/notifications';
import depotService from '../../services/request/depot/depotService';
import EventBus from '../../events/EventBus';

export default {
  name: 'CreateProductDialog',
  data: () => ({
    loading: false,
    product: {
      uuid_cell: '',
      ns_code: '',
      items_amount: '',
      image: null,
    },
  }),
  validations: {
    product: {
      uuid_cell: { required },
      ns_code: { required, nsCodeMask },
      items_amount: { required, minValue: minValue(1) },
    },
  },
  mixins: [language, notifications, validationMixin],
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    cells: {
      required: true,
    },
  },
  methods: {
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('uuid_cell', this.product.uuid_cell);
          formData.append('ns_code', this.product.ns_code);
          formData.append('items_amount', this.product.items_amount);
          if (this.product.image) {
            formData.append('image', this.product.image);
          }
          await depotService.addProduct(formData);
          this.setSuccessNotification('Продукт успішно додано!');
          EventBus.$emit('added-product');
          this.loading = false;
          this.clearProduct();
          this.$v.$reset();
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    clearProduct() {
      Object.keys(this.product).forEach((key) => {
        this.product[key] = null;
      });
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    itemsAmountErrors() {
      const errors = [];
      if (!this.$v.product.items_amount.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.product.items_amount.required
      && errors.push(this.language.isRequired(this.language.AMOUNT));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.product.items_amount.minValue
      && errors.push(`${this.language.AMOUNT} повинна бути більшою за 1!`);
      return errors;
    },
    uuidCellErrors() {
      const errors = [];
      if (!this.$v.product.uuid_cell.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.product.uuid_cell.required
      && errors.push(this.language.isRequired('UUID комірки'));
      return errors;
    },
    nsCodeErrors() {
      const errors = [];
      if (!this.$v.product.ns_code.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.product.ns_code.required
      && errors.push(this.language.isRequired(this.language.NS_CODE));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.product.ns_code.nsCodeMask
      && errors.push(this.language.BAR_CODE_VALIDATION);
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
